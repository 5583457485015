.button{
    width: 230px;
    height: 42px;
    background: #26D367 0% 0% no-repeat padding-box;
    border-radius: 21px;
    opacity: 1;
    border: #26D367;
    font-family: Switzer-Medium !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #FFFFFF !important;
}
@media only screen and (max-width: 500px) {
    .button{
        font-size: 14px !important;
    }
}
#fixedbutton {
    position: fixed;
    bottom: 15px;
    right: 15px; 
}
.callbutton {
    width: 195px;
    height: 42px;
    background: #a91414;
    border-radius: 11px;
    border: 1px solid #e64e4e;
    font-family: Switzer-Medium !important;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px; /* Space between icon and number */
    padding: 5px 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a slight shadow for better visibility */
}

/* Ensure the call icon is aligned */
.callimg {
    width: 55px;
    height: 55px;
    margin-left: -74px;
}
.button{
    width: 230px;
    height: 42px;
    background: #26D367 0% 0% no-repeat padding-box;
    border-radius: 21px;
    opacity: 1;
    border: #26D367;
    font-family: Switzer-Medium !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #FFFFFF !important;
}
@media only screen and (max-width: 500px) {
    .button{
        font-size: 14px !important;
    }
}
#fixedbutton {
    position: fixed;
    bottom: 15px;
    right: 15px; 
}

#fixedcallbutton {
    position: fixed;
    bottom: 70px;
    right: 19px;
    z-index: 999; /* Ensures it's above other elements */
}

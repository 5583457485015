@font-face {
  font-family: Switzer-Black;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Black.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-BlackItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-BlackItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Bold;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Bold.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-BoldItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-BoldItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Extrabold;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Extrabold.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-ExtraboldItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-ExtraboldItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Extralight;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Extralight.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-ExtralightItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-ExtralightItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Italic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Italic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Light;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Light.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-LightItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-LightItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Medium;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Medium.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-MediumItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-MediumItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Regular;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Regular.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Semibold;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Semibold.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-SemiboldItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-SemiboldItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-Thin;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-Thin.otf");
  font-display: swap;
}
@font-face {
  font-family: Switzer-ThinItalic;
  src: url("../assets/fonts/Switzer_Complete/Fonts/OTF/Switzer-ThinItalic.otf");
  font-display: swap;
}
@font-face {
  font-family: Satoshi-Bold;
  src: url("../assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf");
  font-display: swap;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url("../assets/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf");
  font-display: swap;
}
@font-face {
  font-family: GeneralSans-Bold;
  src: url("../assets/fonts/GeneralSans_Complete/Fonts/OTF/GeneralSans-Bold.otf");
  font-display: swap;
}
@font-face {
  font-family: GeneralSans-Medium;
  src: url("../assets/fonts/GeneralSans_Complete/Fonts/OTF/GeneralSans-Medium.otf");
  font-display: swap;
}
body {
  font-family: Switzer-Medium !important;
  color: #141c26 !important;
  
}
/* scrolltabs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-size: 17px !important;
  color: #141c26;
  letter-spacing: 0px;
  line-height: 19px;
  border-radius: 2px !important;
  background:#0D1282 !important;
  color: #FFFFFF !important;
  padding: 5px !important;
}
.nav-link {
  font-size: 18px !important;
}
#tablinks {
  color: #141c26;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
}
#tabactive {
  border-bottom: none;
}
.tabscrollclass {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
}
.nav-link{
  transition: none !important;
}
.cursorPointer {
  cursor: pointer !important;
}
a {
  text-decoration: none !important;
}
@media (max-width: 1023px) {
  .dropdown-menu {
    border: none !important;
    border-radius: 0rem !important;
  }
}
.studentimage {
  position: relative;
}
.studentvideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.position{
  position:relative !important;
}
.slick-slider {
  width: 100%;
  overflow: hidden;
}
.slick-list {
  position: relative;
  display: block !important;
  width: 100%;
}
.slick-slide div {
  width: auto;
  /* margin-right: 10px; */
}
.slick-slide img {
  width: calc(100% - 10px) !important;
}
.slick-prev,
.slick-next {
  z-index: 999 !important;
  width: 48px !important;
  height: 48px !important;
}
.slick-prev {
  left: 0px !important;
}
.slick-next {
  right: 0px !important;
}
#carouselimagewidth{
  width: calc(100% - 0px) !important;
}
/* loader css */
.APIPreLoader{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-color: rgba(240, 240, 240,.5);
}
.loader {
  color: #DB1B0D;
  font-size: 20px;
  margin: 100px auto;
  top: 35%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
.carousel-indicators [data-bs-target]{
  /* width:13px !important;
  height: 13px !important;
  border-radius: 50% !important;
  background-color: #141C2673 !important; */
  display: none !important;
}     
.formstar{
  color: #DB1B0D;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.dot{
  vertical-align: -moz-middle-with-baseline !important;
}
.btn:disabled{
  background-color: #DB1B0D !important;
  border-color: #DB1B0D !important;
  opacity: 0.6;
}
.react-tel-input .form-control{ 
  width: 100% !important; 
  height:auto !important;
  font-size: 16px !important;
  font-family: Switzer-Regular !important;
  border: 1px solid #ced4da !important;
}
@media only screen and (max-width:500px){
  .react-tel-input .form-control{ 
    font-size: 13px !important;
  }
}
.react-tel-input .country-list{
  text-align: start;
}
.PhoneInputInput{
   display: block;
   width: 100%;
   padding: 0.375rem 0.75rem;
   font-size: 16px;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border-radius: 0.375rem;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  @media only screen and (max-width:500px){
    .PhoneInputInput{
      font-size: 13px;
     }
  }
  .PhoneInputInput:focus{
   color: #212529;
   background-color: #fff;
   border-color: #86b7fe;
   outline: 0;
   box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
.captachafont{
  font-size: 14px !important;
}
@media only screen and (max-width:500px){
  .captachafont{
    font-size: 12px !important;
   }
}
.table td{
  padding: 7px 4px !important;
  border-bottom: 1px solid #D9DFE6 ;
  background-color: #F4F6F8 !important;
}
.carouselimage{
  width:100%;
  height: 100%;
  position: relative !important;
}
input[type="date"]::before {
  color: #999999;
  content: attr(placeholder);
}
input[type="date"] {
  color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "" !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url('../assets/images/payment/calendar.svg') no-repeat;
  cursor: pointer;
}
.payment {
  text-align: center;
}
.containerProgress {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 50%;
}
.containerProgress::before {
  content: '';
  background-color: #818C98;
  position: absolute;
  top: 25%;
  left: 0;
  transform: translateY(-50%);
  height: 2px;
  width: 100%;
  z-index: -1;
  transition: 1.5s ease;
}
.circle:last-child.containerProgress::before {
  content: none !important;
  background-color: #fff !important;
}
.progress {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  z-index: -1;
  transition: 2s ease;
}
.circle {
  background-color: #818C98;
  color: #fff;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle.active {
  background-color: #DB1B0D;
}
@media only screen and (max-width:767px) {
  .containerProgress {
   width: 100%;
   }
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #141C26 !important;
    opacity: 1;
}
/* Accordian */
.accordion-button:not(.collapsed) {
  color: inherit !important;
  background-color: #D9DFE6 !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  background-color: #D9DFE6 !important;
  border-radius: 5px 5px 0px 0px !important;
  opacity: 1;
  border-color: #818C98 !important;
}
.accordion{
  --bs-accordion-border-color: #818C98 !important;
  --bs-accordion-btn-bg: #D9DFE6 !important;
  --bs-accordion-btn-icon-width: 29px !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}
.accordion-button::after {
  flex-shrink: 0;
  order: -1;
  margin-left: 0 !important;
  margin-right: 2%;
  content: "";
  background-image: url("../assets/images/upsc/plus.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../assets/images/upsc/minus.svg") !important;
  transform: none;
}
#accordionExample .accordion-item {
  border-top: 1px solid #818C98;
  border-radius: 5px;
}
/* Table */
.prelimstable .table td{
  padding: 1.5% 1rem !important;
}
.prelimstable .table>:not(caption)>*>* {
  padding: 0;
}
/* Optional test boxes */
#optionaltest ul li {
  background: url('../assets/images/upsc/circle_check.svg') no-repeat left center;
  padding: 3rem 10px 10px 3.5rem;
  list-style: none;
}
@media only screen and (max-width:767px){
  #optionaltest ul li{
    padding: 2rem 10px 10px 3.5rem;
}
}
/* youtube size */
.videoheight{
  height: 350px;
}
@media only screen and (max-width: 550px){
  .videoheight{
    height: auto !important;
  }
}
@media only screen and (max-width: 767px){
  .videoheight{
    height: 300px;
  }
}
@media only screen and (min-width: 768px) {
  #homeyoutube{
    height: 324px !important;
    width: 640px !important;
  }
}
@media only screen and (min-width: 992px){
  .sticky-top{
    top: 2.5rem !important;
    background-color: white;
    z-index: -1;
    width: 100%;
  }
}
.offcanvas-active body {
  overflow: none !important;
  }
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: #DB1B0D !important;
  border-color: #DB1B0D !important;
}
/* CSAT */
.csattable .row div{
  border-right: 1px solid #b4b9bd;
  border-bottom: 1px solid #b4b9bd;
  padding: 12px;
}
.csattable .csathead div{
  background-color: #001e62;
}
.csattable .row div:last-child{
  border: none;
}
.form-check-input:checked {
  background-color: #DB1B0D !important;
  border-color: #DB1B0D !important;
}
/* Newheader changes */
@media only screen and (min-width:992px) and (max-width:1024px) {
  .newnav{
    font-size: 15px !important;
  }
}
@media only screen and (min-width:320px) and (max-width:374px) {
  .newnav{
    font-size: 14px !important;
  }
}
.newnav{
  font-size: 18px;
  color: #A60E13;
  background: transparent;
  border: none;
}

.newnav:hover{
  background-color: #A60E13;
  color: #FFFFFF;
}
.custom-border {
  position: relative;
}
.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 3px #959595 solid;
  border-width: thin;
  top: 80%;
  right: 0;
  height: 15%;
  margin-top: auto;
  margin-bottom: auto;
}
.custom-border1 {
  position: relative;
}
.custom-border1:after {
  content: " ";
  position: absolute;
  border-left: 3px #A60E13 solid;
  border-width: thin;
  top: 30%;
  right: 0;
  height: 45%;
  margin-top: auto;
  margin-bottom: auto;
}
.blur
{ 
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.623);
}
.closebtn{
  top: -30px;
  right:-5px;
}
.navbar {
  --bs-navbar-padding-y: 6px !important;
}
#redcolor{
  color: #db1b0d !important;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

